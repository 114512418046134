import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { RouteComponentProps } from "react-router-dom";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import {
  ListCredLibraryTypes,
  useDepartmentId,
} from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import ConfirmDialog from "../Common/ConfirmDialog";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import FileDragNDrop from "../Common/FileDragNDrop";
import FormError from "../Common/FormError";
import { FgInput, FgSelect, FgUseForm } from "../Common/FormGroups";
import RouteChangedGuard from "../Common/RouteChangedGuard";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ConfirmCourseAssignmentDialog from "./ConfirmCourseAssignmentDialog";
import NewCourseCategoryDialog from "./Dialogs/NewCourseCategoryDialog";
import NewCourseDialog from "./Dialogs/NewCourseDialog";
import CourseCategoryTypeahead from "./Typeaheads/CourseCategoryTypeahead";
import CourseCodeTypeahead from "./Typeaheads/CourseCodeTypeahead";
import CourseNameTypeahead from "./Typeaheads/CourseNameTypeahead";
import CourseRecordTypeahead from "./Typeaheads/CourseRecordTypeahead";
import CheckboxList from "../Common/CheckboxList";

const GetSubmittedFrom = () => {
  return document.getElementById("courseform")?.getAttribute("submittedfrom");
};

const SetSubmittedFrom = (from: string) => {
  return document
    .getElementById("courseform")
    ?.setAttribute("submittedfrom", from);
};

function CourseRecord(props: RouteComponentProps<any>) {
  const id = Number(props.match?.params?.id) || (null as any);
  const [isNew, setIsNew] = useState(!id);
  const [loading, setLoading] = useState(true);

  const departmentId = useDepartmentId();
  useEffect(() => {
    if (departmentId.current > 0) {
      setLoading(true);
      if (departmentId.changed && departmentId.previous > 0) {
        setFiles([]);
        setEmployees([]);
        if (isNew) {
          setModel({} as any);
          setOriginalModel({} as any);
          setValuesFromModel({} as any);
        } else {
          setOriginalModel(model);
          setTimeout(() => {
            props.history.push("/courses");
          }, 200);
          return; //exit
        }
      }
      courseCategories.getData();
      trainingTypesList.getData();
      setIsNew(!id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [libType, setLibType] = useState(ApsModels.CredLibraryTypeEnum.All);

  const [takenDates, setTakenDates] = useState<{
    takenFromDate: Date;
    takenToDate: Date;
  }>();
  const currentData = useNfirsGetData(
    async () => ApsServices.http.credCourseRecord.getTakenCourse(id),
    "Course Record"
  );
  useEffect(() => {
    if (currentData.status === NfirsFetchStatus.Complete && currentData.data) {
      setTakenDates({
        takenFromDate: currentData.data.takenFromDate,
        takenToDate: currentData.data.takenToDate,
      });
      if (!commonService.isNullOrWhitespace(currentData.data?.instructor)) {
        setEmployees2([
          {
            id: 0,
            name: currentData.data?.instructor || "",
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.status]);

  const courseCategories = useNfirsGetData(
    async () => ApsServices.http.credCourseRecord.getCourseCategories(libType),
    "Course Categories"
  );
  const courseNumbers = useNfirsGetData(
    async () =>
      await ApsServices.http.credCourseRecord.getCoursesByNumber(libType),
    "Course Numbers"
  );
  const courseNames = useNfirsGetData(
    async () =>
      await ApsServices.http.credCourseRecord.getCoursesByName(libType),
    "Course Names"
  );

  const trainingTypesList = useNfirsGetData(
    ApsServices.http.credCommon.getTrainingType,
    "Training Types"
  );

  useEffect(() => {
    courseNumbers.getData();
    courseNames.getData();
    courseCategories.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libType, departmentId]);

  useEffect(() => {
    if (
      currentData.status === NfirsFetchStatus.Failed &&
      currentData.errorCode === 404
    ) {
      props.history.push(`/courses`);
      return;
    }
    if (currentData.status === NfirsFetchStatus.Complete && currentData.data) {
      setModel((p) => {
        return {
          ...p,
          ...currentData.data,
        };
      });
      setValuesFromModel(currentData.data);
      setLoading(false);

      if (currentData.data && courseNames.data) {
        courseNames.data.forEach((cn) => {
          if (cn.id === currentData.data?.credCourseId) {
            setModel((p) => {
              const extModel = {
                ...p,
                takenToDateString: commonService.toStartOfDayDateString(
                  currentData.data?.takenToDate
                ),
                takenFromDateString: commonService.toStartOfDayDateString(
                  currentData.data?.takenFromDate
                ),
                _category: cn.credCourseCategoryEntryId,
              };
              setOriginalModel({ ...extModel });
              return { ...extModel };
            });
            setTimeout(() => {
              setValue("courseCategory", cn.credCourseCategoryEntryId);
              setCourseDetails(cn.id, true);
            }, 100);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.status]);

  useEffect(() => {
    if (
      courseCategories.status === NfirsFetchStatus.Complete &&
      courseCategories.data &&
      courseNumbers.status === NfirsFetchStatus.Complete &&
      courseNumbers.data &&
      courseNames.status === NfirsFetchStatus.Complete &&
      courseNames.data &&
      trainingTypesList.status === NfirsFetchStatus.Complete &&
      trainingTypesList.data
    ) {
      if (id) {
        currentData.getData();
      } else {
        setLoading(false);

        //Set Default Training Type to "Company Training" (2)
        setValue("trainingTypeId", 2);
        setModel((prev) => {
          return {
            ...prev,
            trainingTypeId: 2,
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseCategories.status,
    courseNumbers.status,
    courseNames.status,
    trainingTypesList.status,
  ]);

  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );

  useEffect(() => {
    if (canAddEmployees()) {
      groups.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, departmentId, groups.status]);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setUsers((list) => {
        return [
          ...(getEmployees.data || []).map((i) => {
            return {
              id: i.id,
              name: `${i.firstName} ${i.lastName}`,
              firstName: i.firstName,
              lastName: i.lastName,
            };
          }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValue,
    setValuesFromModel,
    formRef,
  } = FgUseForm({
    //Detail
    courseCategory: {
      displayName: "Course Category",
      validation: {
        required: false,
      },
    },
    courseNumber: {
      displayName: "Course Code",
      validation: {
        required: true,
      },
    },
    courseName: {
      displayName: "Course Name",
      validation: {
        required: true,
      },
    },
    hours: {
      displayName: "Course Hours",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            // if (model.qualifyForEmsCe) {
            //   return (Number(val) || 0) > 0; //!commonService.isNullOrEmpty(val);
            // }
            return !commonService.isNullOrEmpty(val);
          },
        },
      },
    },
    //Completion
    employeeName: {
      displayName: "Employee Name",
      validation: {
        required: false,
        validate: {
          employees: (val: any) => {
            if (isNew && claims.course.canAddAll && employees.length === 0) {
              return "Please Select Employee(s)";
            }
            return true;
          },
        },
      },
    },
    providerNumber: {
      displayName: "CE Provider Number",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (model.courseCharacteristicsDto?.isEmsCe) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    jacCode: {
      displayName: "JAC Code",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (model.courseCharacteristicsDto?.isJac) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          lettersOnly: (val: string) => {
            if (!commonService.validations.lettersOnly(val)) {
              return "Please enter 3 letters only";
            }
            return true;
          },
        },
      },
    },
    lmsEnrollmentId: {
      displayName: "LMS Enrollment ID",
      validation: {
        required: false,
      },
    },
    dateTaken: {
      displayName: "Date Taken",
      validation: {
        required: false,
        validate: {
          dateTakenError: (val: any) => {
            if (commonService.isNullOrEmpty(model.takenFromDateString)) {
              return "Start and End Dates are required";
            }
            if (commonService.isNullOrEmpty(model.takenToDateString)) {
              return "Both Dates are required";
            }
            if (model.takenFromDateString > model.takenToDateString) {
              return "End Date must be greater than Start Date";
            }
            return true;
          },
        },
      },
    },
    locationTaken: {
      displayName: "Location Taken",
      validation: {
        required: false,
      },
    },
    instructor: {
      displayName: "Instructor",
      validation: {
        required: false,
      },
    },
    trainingTypeId: {
      displayName: "Training Type",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (model.courseCharacteristicsDto?.isIso) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    courseDescription: {
      displayName: "Course Description",
      validation: {
        required: false,
      },
    },
    courseObjective: {
      displayName: "Course Objectives",
      validation: {
        required: false,
      },
    },
    // courseQualificationEnum: {
    //   displayName: "Course Characteristics",
    //   validation: {
    //     required: true,
    //   },
    // },
  });

  const [model, setModel] = useState<ApsModels.ICredTakenCourseUpdateDto>({
    trainingTypeId: 2,
  } as any);
  const [orignalModel, setOriginalModel] = useState(model);
  const [saving, setSaving] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [filesToInclude, setFilesToInclude] = useState<number[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const getCourseQualifications = (
    data: ApsModels.ICredTakenCourseUpdateDto
  ) => {
    return {
      providerNumber: data.courseCharacteristicsDto?.isEmsCe
        ? data.providerNumber
        : (null as any),
      trainingTypeId: data.courseCharacteristicsDto?.isIso
        ? data.trainingTypeId
        : (null as any),
    };
  };

  const submit = async (data: ApsModels.ICredTakenCourseUpdateDto) => {
    if (isFromLms()) {
      return; //do nothing
    }
    if (GetSubmittedFrom() !== "submit") {
      if (isNew) {
        setShowAssignDialog(true);
      } else {
        assignCourseNow([]);
      }
      return;
    }
    let action: Promise<any>;
    const qualifyForEmsCe = model.courseCharacteristicsDto?.isEmsCe;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    if (isNew && claims.course.canAddAll) {
      formData.append(
        "jsonData",
        JSON.stringify({
          ...model,
          ...getCourseQualifications(model),
          instructor: employees2[0]?.name || "",
          userIds: employees.map((e) => e.id),
          qualifyForEmsCe,
          fileIdsToCopy: filesToInclude,
        } as ApsModels.ICredTakenCourseAdminAddDto)
      );
      action = ApsServices.http.credCourseRecord.adminAddTakenCourse(formData);
    } else {
      if (claims.course.canAddAll) {
        formData.append(
          "jsonData",
          JSON.stringify({
            ...model,
            ...getCourseQualifications(model),
            instructor: employees2[0]?.name || "",
            userId: currentData.data?.userId,
            idOfFilesToDelete: filesToDelete,
            qualifyForEmsCe,
            fileIdsToCopy: filesToInclude,
          } as ApsModels.ICredTakenCourseAdminUpdateDto)
        );
        action =
          ApsServices.http.credCourseRecord.adminUpdateTakenCourse(formData);
      } else {
        formData.append(
          "jsonData",
          JSON.stringify({
            ...model,
            ...getCourseQualifications(model),
            instructor: employees2[0]?.name || "",
            idOfFilesToDelete: filesToDelete,
            qualifyForEmsCe,
            fileIdsToCopy: filesToInclude,
          } as ApsModels.ICredTakenCourseUpdateDto)
        );
        action = ApsServices.http.credCourseRecord.saveOwnTakenCourse(formData);
      }
    }

    setSaving(true);
    await action
      .then((rtn) => {
        setSaving(false);
        setFiles([]);
        setEmployees([]);
        setOriginalModel({ ...model });
        toastStore.showToast("Course Saved", "success");
        navigateBack(
          isNew
            ? commonService.doneUrls.created
            : commonService.doneUrls.submitted
        );
      })
      .catch((err) => {
        setSaving(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed Saving Course.", err);
        }
      });
  };

  const assignCourseNow = async (
    employees: {
      name: string;
      id: number;
    }[]
  ) => {
    if (isFromLms()) {
      return; //do nothing
    }
    let action: Promise<any>;
    const qualifyForEmsCe = model.courseCharacteristicsDto?.isEmsCe;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    if (isNew && claims.course.canAddAll) {
      formData.append(
        "jsonData",
        JSON.stringify({
          ...model,
          ...getCourseQualifications(model),
          instructor: employees2[0]?.name || "",
          userIds: employees.map((e) => e.id),
          qualifyForEmsCe,
          fileIdsToCopy: filesToInclude,
        } as ApsModels.ICredTakenCourseAdminAddDto)
      );
      action =
        ApsServices.http.credCourseRecord.adminAddAssignedCourse(formData);
    } else {
      if (claims.course.canAddAll) {
        formData.append(
          "jsonData",
          JSON.stringify({
            ...model,
            ...getCourseQualifications(model),
            instructor: employees2[0]?.name || "",
            userId: currentData.data?.userId,
            idOfFilesToDelete: filesToDelete,
            qualifyForEmsCe,
            fileIdsToCopy: filesToInclude,
          } as ApsModels.ICredTakenCourseAdminUpdateDto)
        );
        action =
          ApsServices.http.credCourseRecord.adminUpdateAssignedCourse(formData);
      } else {
        formData.append(
          "jsonData",
          JSON.stringify({
            ...model,
            ...getCourseQualifications(model),
            instructor: employees2[0]?.name || "",
            idOfFilesToDelete: filesToDelete,
            qualifyForEmsCe,
            fileIdsToCopy: filesToInclude,
          } as ApsModels.ICredTakenCourseUpdateDto)
        );
        action = ApsServices.http.credCourseRecord.assignOwnCourse(formData);
      }
    }

    setAssigning(true);
    await action
      .then((rtn) => {
        setAssigning(false);
        setFiles([]);
        setEmployees([]);
        setOriginalModel({ ...model });
        toastStore.showToast("Course Saved", "success");
        navigateBack(commonService.doneUrls.assigned);
      })
      .catch((err) => {
        setAssigning(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed Saving Course.", err);
        }
      });
  };

  const [confirmUnsaved, setConfirmUnsaved] = useState(false);
  const hasChanges = () => {
    return (
      !commonService.isEqual(model, orignalModel) ||
      files.length > 0 ||
      employees.length > 0
    );
  };

  const [claims] = useState(commonService.friendlyClaims);
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  // const handleSearch = async (query: string) => {
  //   if ((query || "").trim() === "") {
  //     setUsers([]);
  //     setIsLoading(false);
  //     return;
  //   }
  //   setIsLoading(true);
  //   await ApsServices.http.credCommon
  //     .coursesTypeAheadUser({
  //       search: query,
  //       isAnd: false,
  //       recordCount: 10,
  //       shiftId: null as any,
  //       rankId: selectedRank || (null as any),
  //     })
  //     .then((items) => {
  //       const options = items.map((i) => ({
  //         id: i.id,
  //         name: `${i.firstName} ${i.lastName}`,
  //         firstName: i.firstName,
  //         lastName: i.lastName,
  //       }));
  //       setUsers(options);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setUsers([]);
  //       setIsLoading(false);
  //     });
  // };

  const [users2, setUsers2] = useState([] as any[]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [employees2, setEmployees2] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);
  const ref2 = React.createRef<AsyncTypeahead<any>>();

  const handleSearch2 = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers2([]);
      setIsLoading2(false);
      return;
    }

    setIsLoading2(true);

    await ApsServices.http.credCommon
      .courseTypeAheadInstructor({
        search: query,
        isAnd: false,
        recordCount: 10,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        options.push({
          id: -1,
          name: query,
          firstName: query,
          lastName: "",
        });

        setUsers2(options);
        setIsLoading2(false);
      })
      .catch((err) => {
        setUsers2([]);
        setIsLoading2(false);
      });
  };

  const showFileUpload = () => {
    return true; //https://aps-software.atlassian.net/browse/APS110-43
    // if (!isNew) {
    //   return true;
    // }
    // if (isNew && !claims.course.canAddAll) {
    //   return true;
    // }
    // return false;
  };

  const canAddEmployees = () => {
    return (
      claims.course.canAddAll ||
      (!claims.course.canAddAll && claims.course.canViewAll)
    );
  };

  const navigateBack = (doneUrl?: string) => {
    if (props.history.action !== "POP") {
      commonService.routeGoBack(props, doneUrl);
    } else {
      props.history.push("/courses" + (doneUrl || ""));
    }
  };

  const isFromLms = () => {
    if (!isNew && currentData.data && currentData.data.isFromLms) {
      return true;
    }
    return false;
  };

  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [newCourse, setNewCourse] = useState(false);
  const [isAPScategory, setIsApsCategory] = useState(false);

  const setCourseDetails = (
    id: number,
    useCurrentData = false,
    newCourse?: ApsModels.ICredCourseRecordOutputDto
  ) => {
    setValue("courseNumber", id);
    setValue("courseName", id);
    const dtl = newCourse || courseNames.data?.find((x) => x.id === id);
    if (dtl && !useCurrentData) {
      setValue("hours", dtl.hours);
      setValue("courseDescription", dtl.courseDescription || "");
      setValue("courseObjective", dtl.courseObjective || "");
      setValue("trainingTypeId", dtl.trainingTypeId || null);
      setValue("jacCode", dtl.jacCode || null);
      setValue("providerNumber", dtl.providerNumber || null);
      //setValue("courseQualificationEnum", dtl.courseQualificationEnum || null); //TODO:

      setTimeout(() => {
        setModel((p) => {
          return {
            ...p,
            hours: dtl.hours,
            courseDescription: dtl.courseDescription,
            courseObjective: dtl.courseObjective,
            trainingTypeId: dtl.trainingTypeId || (null as any),
            courseCharacteristicsDto: {
              isEmsCe: dtl.courseCharacteristicsDto?.isEmsCe || false,
              isIsa: dtl.courseCharacteristicsDto?.isIsa || false,
              isIso: dtl.courseCharacteristicsDto?.isIso || false,
              isJac: dtl.courseCharacteristicsDto?.isJac || false,
            },
            jacCode: dtl.jacCode || (null as any),
            providerNumber: dtl.providerNumber || (null as any),
            qualifyForEmsCe: dtl.courseCharacteristicsDto?.isEmsCe,
            //TODO:
            // courseQualificationEnum:
            //   dtl.courseQualificationEnum || (null as any),
          };
        });
      }, 100);
    }
  };

  const clearCourseDetails = () => {
    setValue("courseNumber", undefined);
    setValue("courseName", undefined);
    setValue("hours", undefined);
    setValue("courseDescription", undefined);
    setValue("courseObjective", undefined);
    setValue("courseQualificationEnum", undefined);
    setValue("trainingTypeId", undefined);
  };

  const tempFiles = useNfirsGetData(
    async () =>
      ApsServices.http.credTemplate.listCourseTemplateFiles(model.credCourseId),
    "Files"
  );

  useEffect(() => {
    setFilesToInclude([]);
    if (model.credCourseId && isNew) {
      tempFiles.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.credCourseId]);
  useEffect(() => {
    if (tempFiles.data) {
      setFilesToInclude(tempFiles.data?.map((x) => x.id) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFiles.data]);

  return (
    <>
      {showAssignDialog && (
        <ConfirmCourseAssignmentDialog
          data={{
            fromDate: model.takenFromDateString,
            toDate: model.takenToDateString,
            employees: [...employees],
          }}
          onChange={(data) => {
            setShowAssignDialog(false);
            assignCourseNow(data.employees);
          }}
          onClose={(save) => {
            setShowAssignDialog(false);
          }}
        ></ConfirmCourseAssignmentDialog>
      )}
      <RouteChangedGuard
        when={true}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => {
          return hasChanges();
        }}
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
      />
      <ConfirmDialog
        show={!!errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(errorCode)}
        message={commonService.getConcurrencyMessage(errorCode)}
        done={(rtn) => {
          if (errorCode === 409) {
            setErrorCode(0);
            setLoading(true);
            currentData.getData();
          } else {
            props.history.push(`/courses`);
          }
        }}
      />
      <ConfirmDialog
        show={confirmUnsaved}
        buttons="yesno"
        title="Confirmation"
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
        done={(rtn) => {
          setConfirmUnsaved(false);
          if (rtn === "yes") {
            props.history.push(`/courses`);
          }
        }}
      />
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you reall want to delete all employees selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setEmployees([]);
          }
          setDeleteAll(false);
        }}
      />
      <ConfirmDialog
        show={confirmDelete}
        buttons="yesno"
        title="Confirmation"
        message={`Do you really want to delete this ${
          currentData.data?.isTaken ? '"Taken"' : '"Assigned"'
        } course record?`}
        done={(rtn) => {
          setConfirmDelete(false);
          if (rtn === "yes") {
            setLoading(true);
            ApsServices.http.credCourseRecord
              .adminDeleteAssignedOrTakenCourse(model.id)
              .then((data) => {
                toastStore.showToast("Course Record Deleted", "success");
                setLoading(false);
                props.history.push(`/courses` + commonService.doneUrls.deleted);
              })
              .catch((error) => {
                toastStore.showError("Failed Deleting Course Record", error);
                setLoading(false);
              });
          }
        }}
      />
      {newCategory && (
        <NewCourseCategoryDialog
          onClose={(saved) => {
            setNewCategory(false);
          }}
          onChange={(data) => {
            const categ = data as ApsModels.ICredCourseCategoryOutputDto;
            courseCategories.setData((list) => {
              return [...(list || []), categ];
            });
            courseCategories.getData();
            if (categ) {
              setModel((p) => {
                return {
                  ...p,
                  _category: commonService.getNumber(categ.id),
                  credCourseId: undefined,
                } as any;
              });
              clearCourseDetails();
            }
            setNewCategory(false);
          }}
        ></NewCourseCategoryDialog>
      )}
      {newCourse && (
        <NewCourseDialog
          data={{
            category: courseCategories.data?.find(
              (x) => x.id === (model as any)._category
            ),
          }}
          onClose={(saved) => {
            setNewCourse(false);
          }}
          onChange={(data) => {
            const course = data as ApsModels.ICredCourseRecordOutputDto;
            courseNames.setData((list) => {
              return [...(list || []), course];
            });
            courseNumbers.setData((list) => {
              return [...(list || []), course];
            });
            courseNames.getData();
            courseNumbers.getData();

            if (course) {
              setModel((p) => {
                return {
                  ...p,
                  credCourseId: course.id,
                };
              });
              setCourseDetails(course.id, false, course);
            }
            setNewCourse(false);
          }}
        ></NewCourseDialog>
      )}
      {loading && (
        <div className="position-relative flex-1">
          <CommonSpinner></CommonSpinner>
        </div>
      )}
      <form
        id="courseform"
        className={`flex flex-1 flex-col ${loading ? "display-none" : ""}`}
        onSubmit={(e) => {
          //console.log(formState.errors);
          handleSubmit(submit)(e);
        }}
        ref={formRef}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  {isNew ? "Add" : isFromLms() ? "View" : "Edit"} Course
                </span>
              </div>
              <div>
                {!isNew && claims.course.canAddAll && (
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    disabled={saving || assigning}
                    onClick={(e) => {
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </button>
                )}
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={saving || assigning}
                  onClick={(e) => {
                    navigateBack();
                  }}
                >
                  {isFromLms() ? "Close" : "Cancel and Close"}
                </button>
                {!isFromLms() && (
                  <>
                    <button
                      id="submit"
                      className="btn btn-primary"
                      type="submit"
                      disabled={saving || assigning}
                      onClick={(e) => {
                        SetSubmittedFrom("submit");
                      }}
                      style={{
                        float: "right",
                      }}
                    >
                      {saving ? "Saving..." : "Submit"}
                    </button>
                    <button
                      id="assign-update"
                      className="btn btn-success"
                      type="submit"
                      disabled={saving || assigning}
                      onClick={(e) => {
                        SetSubmittedFrom("assign-update");
                      }}
                    >
                      {assigning ? "Assigning..." : "Assign"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>1. Course Details</strong>
                  {isNew && (
                    <>
                      <div className="pt-4 pb-2">
                        <label>Course Library</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setLibType(Number(e.target.value) || 0);
                            setModel((p) => {
                              return {
                                ...p,
                                _category: undefined,
                              } as any;
                            });
                            setValue("courseCategory", undefined);
                            clearCourseDetails();
                          }}
                        >
                          {ListCredLibraryTypes.map((i) => (
                            <option key={i.value} value={i.value}>
                              {i.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label>Search Existing Course</label>
                      </div>
                      <div>
                        <CourseRecordTypeahead
                          libType={libType}
                          onChange={(data, ref) => {
                            if (data && data.length > 0) {
                              ref.current?.clear();
                              const course = data[0]
                                .model as ApsModels.ICourseTypeAheadOutputDto;
                              if (course) {
                                setModel((p) => {
                                  return {
                                    ...p,
                                    credCourseId: course.id,
                                  };
                                });
                                setCourseDetails(course.id);
                              }
                            }
                          }}
                        ></CourseRecordTypeahead>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className={isNew ? "pt-0" : "pt-4"}>
                    <div className="flex flex-center">
                      <label className="flex-1 required-label">
                        Course Category
                      </label>
                      <div>
                        {!isFromLms() && (
                          <span
                            className="text-primary pointer"
                            onClick={(e) => {
                              setNewCategory(true);
                            }}
                          >
                            New Category
                          </span>
                        )}
                      </div>
                    </div>
                    {!isFromLms() && (
                      <CourseCategoryTypeahead
                        libType={libType}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const categ = data[0]
                              .model as ApsModels.ICredCourseCategoryOutputDto;
                            setIsApsCategory(categ.agency === "APS");
                            if (categ) {
                              setModel((p) => {
                                return {
                                  ...p,
                                  _category: commonService.getNumber(categ.id),
                                  credCourseId: undefined,
                                } as any;
                              });
                              clearCourseDetails();
                            }
                          }
                        }}
                      ></CourseCategoryTypeahead>
                    )}
                    <div
                      className={`currentParticipants ${
                        isFromLms() ? "disabled" : "mt-2"
                      }`}
                    >
                      {courseCategories.data
                        ?.filter((x) => x.id === (model as any)._category)
                        ?.map((x) => (
                          <div key={x.id}>
                            <section>{`${x.description}`}</section>
                            <section
                              title="Remove"
                              onClick={() => {
                                setModel((p) => {
                                  return {
                                    ...p,
                                    _category: undefined,
                                    credCourseId: undefined,
                                  } as any;
                                });
                                setValue("courseCategory", undefined);
                                clearCourseDetails();
                                setIsApsCategory(false);
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </section>
                          </div>
                        ))}
                    </div>

                    {/* <FgSelect
                      id="courseCategory"
                      label="Course Category"
                      selectMessage="- Select Course Category -"
                      options={(courseCategories.data || []).map((cat) => {
                        return {
                          label: cat.description,
                          value: cat.id,
                        };
                      })}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            _category: commonService.getNumber(data),
                          } as any;
                        });
                        setValue("courseNumber", undefined);
                        setValue("courseName", undefined);
                      }}
                      registeredField={registry.courseCategory}
                      formState={formState}
                      disabled={!isNew}
                    /> */}

                    <div className="pt-2 flex flex-center">
                      <label className="flex-1 required-label">
                        Course Name
                      </label>
                      <div>
                        {!isFromLms() && (
                          <span
                            className="text-primary pointer"
                            onClick={(e) => {
                              if (!(model as any)._category) {
                                toastStore.showToast(
                                  "Please select a category first.",
                                  "warning"
                                );
                                return;
                              }
                              if (isAPScategory) {
                                toastStore.showToast(
                                  "Course creation is limited to Agency Library categories only. Please select or add an Agency Library category to continue",
                                  "warning"
                                );
                                return;
                              }
                              setNewCourse(true);
                            }}
                          >
                            New Name
                          </span>
                        )}
                      </div>
                    </div>
                    {!isFromLms() && (
                      <CourseNameTypeahead
                        libType={libType}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const course = data[0]
                              .model as ApsModels.ICourseTypeAheadOutputDto;
                            if (course) {
                              setModel((p) => {
                                return {
                                  ...p,
                                  credCourseId: course.id,
                                };
                              });
                              setCourseDetails(course.id);
                            }
                          }
                        }}
                      ></CourseNameTypeahead>
                    )}
                    <FormError
                      field={registry.courseName}
                      formState={formState}
                      fieldDisplayName="Course Name"
                    ></FormError>
                    {formState?.errors?.courseName && (
                      <small className="txt-danger">
                        Course is required. Search Existing or Add New Course
                      </small>
                    )}
                    <div
                      className={`currentParticipants ${
                        isFromLms() ? "disabled" : "mt-2"
                      }`}
                    >
                      {courseNames.data
                        ?.filter((x) => x.id === model.credCourseId)
                        ?.map((x) => (
                          <div key={x.id}>
                            <section>{`${x.name}`}</section>
                            <section
                              title="Remove"
                              onClick={() => {
                                setModel((p) => {
                                  return {
                                    ...p,
                                    credCourseId: undefined,
                                  } as any;
                                });
                                clearCourseDetails();
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </section>
                          </div>
                        ))}
                    </div>

                    {/* <FgSelect
                      id="courseName"
                      label="Course Name"
                      selectMessage="- Select Course Name -"
                      showRequiredLabel={true}
                      options={(courseNames.data || [])
                        .filter((num) => {
                          return (
                            !(model as any)._category ||
                            num.credCourseCategoryEntryId ===
                              (model as any)._category
                          );
                        })
                        .map((num) => {
                          return {
                            label: num.name,
                            value: num.id,
                          };
                        })}
                      onChange={(data) => {
                        const val = commonService.getNumber(data);
                        const hours =
                          courseNumbers.data?.find((c) => c.id === val)
                            ?.hours || (undefined as any);
                        setModel((p) => {
                          return {
                            ...p,
                            credCourseId: val,
                            hours: hours,
                            qualifyForEmsCe:
                              courseNumbers.data?.find((c) => c.id === val)
                                ?.qualifyForEmsCe || false,
                          };
                        });
                        setValue("courseNumber", val);
                        setValue("hours", hours);
                      }}
                      registeredField={registry.courseName}
                      formState={formState}
                      disabled={!isNew}
                    /> */}

                    <div className="pt-2 flex flex-center">
                      <label className="flex-1 required-label">
                        Course Code
                      </label>
                      <div>
                        {!isFromLms() && (
                          <span
                            className="text-primary pointer"
                            onClick={(e) => {
                              if (!(model as any)._category) {
                                toastStore.showToast(
                                  "Please select a category first.",
                                  "warning"
                                );
                                return;
                              }
                              if (isAPScategory) {
                                toastStore.showToast(
                                  "Only Agency categories are allowed to create a new Course",
                                  "warning"
                                );
                                return;
                              }
                              setNewCourse(true);
                            }}
                          >
                            New Number
                          </span>
                        )}
                      </div>
                    </div>
                    {!isFromLms() && (
                      <CourseCodeTypeahead
                        libType={libType}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const course = data[0]
                              .model as ApsModels.ICourseTypeAheadOutputDto;
                            if (course) {
                              setModel((p) => {
                                return {
                                  ...p,
                                  credCourseId: course.id,
                                };
                              });
                              setCourseDetails(course.id);
                            }
                          }
                        }}
                      ></CourseCodeTypeahead>
                    )}
                    {formState?.errors?.courseNumber && (
                      <small className="txt-danger">
                        Course is required. Search Existing or Add New Course
                      </small>
                    )}
                    <div
                      className={`currentParticipants mb-3 ${
                        isFromLms() ? "disabled" : "mt-2"
                      }`}
                    >
                      {courseNumbers.data
                        ?.filter((x) => x.id === model.credCourseId)
                        ?.map((x) => (
                          <div key={x.id}>
                            <section>{`${x.number}`}</section>
                            <section
                              title="Remove"
                              onClick={() => {
                                setModel((p) => {
                                  return {
                                    ...p,
                                    credCourseId: undefined,
                                  } as any;
                                });
                                clearCourseDetails();
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </section>
                          </div>
                        ))}
                    </div>

                    {/* <FgSelect
                      id="courseNumber"
                      label="Course Number"
                      selectMessage="- Select Course Number -"
                      showRequiredLabel={true}
                      options={(courseNumbers.data || [])
                        .filter((num) => {
                          return (
                            !(model as any)._category ||
                            num.credCourseCategoryEntryId ===
                              (model as any)._category
                          );
                        })
                        .map((num) => {
                          return {
                            label: num.number,
                            value: num.id,
                          };
                        })}
                      onChange={(data) => {
                        const val = commonService.getNumber(data);
                        const hours =
                          courseNumbers.data?.find((c) => c.id === val)
                            ?.hours || (undefined as any);
                        setModel((p) => {
                          return {
                            ...p,
                            credCourseId: val,
                            hours: hours,
                            qualifyForEmsCe:
                              courseNumbers.data?.find((c) => c.id === val)
                                ?.qualifyForEmsCe || false,
                          };
                        });
                        setValue("courseName", val);
                        setValue("hours", hours);
                      }}
                      registeredField={registry.courseNumber}
                      formState={formState}
                      disabled={!isNew}
                    /> */}

                    <FgInput
                      id="hours"
                      label="Course Hours"
                      placeHolder="Course Hours"
                      showRequiredLabel={true} //model.qualifyForEmsCe
                      registeredField={registry.hours}
                      formState={formState}
                      autoFocus={true}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            hours: commonService.getNumber(data),
                          };
                        });
                      }}
                      type="number"
                      step="0.01"
                      readOnly={isFromLms()}
                    ></FgInput>

                    <div className="mb-3">
                      {/* <FgSelect
                        id="courseQualificationEnum"
                        label="Course Characteristics"
                        selectMessage="- Select Course Characteristics -"
                        showRequiredLabel={true}
                        options={[
                          {
                            label: "EMS CE",
                            value: ApsModels.CourseQualificationEnum.EmsCe,
                          },
                          {
                            label: "Instructor Qualifications",
                            value:
                              ApsModels.CourseQualificationEnum
                                .InstructorQualifications,
                          },
                          {
                            label: "ISO",
                            value: ApsModels.CourseQualificationEnum.Iso,
                          },
                          {
                            label: "None of the Above",
                            value:
                              ApsModels.CourseQualificationEnum.NoneOfTheAbove,
                          },
                        ]}
                        onChange={(data) => {
                          setModel((p) => {
                            return {
                              ...p,
                              courseQualificationEnum:
                                commonService.getNumber(data),
                              qualifyForEmsCe:
                                commonService.getNumber(data) ===
                                ApsModels.CourseQualificationEnum.EmsCe,
                            } as any;
                          });
                        }}
                        registeredField={registry.courseQualificationEnum}
                        formState={formState}
                      /> */}
                      <label>Course Characteristics</label>
                      <CheckboxList
                        id="courseCharacteristics"
                        disabled={isFromLms()}
                        data={[
                          {
                            label: "EMS CE",
                            value:
                              model.courseCharacteristicsDto?.isEmsCe || false,
                          },
                          {
                            label: "ISA",
                            value:
                              model.courseCharacteristicsDto?.isIsa || false,
                          },
                          {
                            label: "ISO",
                            value:
                              model.courseCharacteristicsDto?.isIso || false,
                          },
                          {
                            label: "JAC",
                            value:
                              model.courseCharacteristicsDto?.isJac || false,
                          },
                        ]}
                        onChange={(data) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              courseCharacteristicsDto: {
                                ...prev.courseCharacteristicsDto,
                                isEmsCe:
                                  data.find((i) => i.label === "EMS CE")
                                    ?.value || false,
                                isIsa:
                                  data.find((i) => i.label === "ISA")?.value ||
                                  false,
                                isIso:
                                  data.find((i) => i.label === "ISO")?.value ||
                                  false,
                                isJac:
                                  data.find((i) => i.label === "JAC")?.value ||
                                  false,
                              },
                            };
                          });
                        }}
                      ></CheckboxList>
                      {/* <label>Course Qualify for EMS CE</label> */}
                      {/* <div className="inline-radio-box-group">
                        <div>
                          <input
                            type="radio"
                            name="qualifyForEmsCe"
                            id="qualifyForEmsCeYes"
                            value="1"
                            checked={model.qualifyForEmsCe || false}
                            onChange={(event) => {
                              setModel((p: any) => {
                                return {
                                  ...p,
                                  qualifyForEmsCe: true,
                                };
                              });
                            }}
                            disabled={isFromLms()}
                          ></input>
                          <label htmlFor="qualifyForEmsCeYes">Yes</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="qualifyForEmsCe"
                            id="qualifyForEmsCeNo"
                            value="0"
                            checked={!model.qualifyForEmsCe || false}
                            onChange={(event) => {
                              setModel((p: any) => {
                                return {
                                  ...p,
                                  qualifyForEmsCe: false,
                                };
                              });
                            }}
                            disabled={isFromLms()}
                          ></input>
                          <label htmlFor="qualifyForEmsCeNo">No</label>
                        </div>
                      </div> */}
                    </div>

                    <div
                      className={
                        model.courseCharacteristicsDto?.isEmsCe
                          ? "mb-3"
                          : "display-none"
                      }
                    >
                      <FgInput
                        id="providerNumber"
                        label="CE Provider Number"
                        placeHolder="CE Provider Number"
                        registeredField={registry.providerNumber}
                        formState={formState}
                        onChange={(data) => {
                          setModel((p: any) => {
                            return {
                              ...p,
                              providerNumber: data,
                            };
                          });
                        }}
                        showRequiredLabel={true}
                        readOnly={isFromLms()}
                      ></FgInput>
                    </div>

                    <div
                      className={
                        model.courseCharacteristicsDto?.isIso
                          ? ""
                          : "display-none"
                      }
                    >
                      <FgSelect
                        id="trainingTypeId"
                        label="ISO Training Type"
                        selectMessage="- Select Training Type -"
                        options={
                          trainingTypesList.data?.map((cat) => {
                            return {
                              label: cat.name,
                              value: cat.id,
                            };
                          }) || []
                        }
                        onChange={(data) => {
                          setModel((p) => {
                            return {
                              ...p,
                              trainingTypeId: commonService.getNumber(data),
                            } as any;
                          });
                        }}
                        registeredField={registry.trainingTypeId}
                        formState={formState}
                        showRequiredLabel={true}
                      />
                    </div>

                    <div
                      className={
                        model.courseCharacteristicsDto?.isJac
                          ? ""
                          : "display-none"
                      }
                    >
                      <FgInput
                        id="jacCode"
                        label="JAC Code"
                        placeHolder="JAC Code"
                        registeredField={registry.jacCode}
                        formState={formState}
                        maxLength={3}
                        onChange={(data) => {
                          setValue("jacCode", data.toUpperCase());
                          setModel((p: any) => {
                            return {
                              ...p,
                              jacCode: data.toUpperCase(),
                            };
                          });
                        }}
                        showRequiredLabel={true}
                        readOnly={isFromLms()}
                      ></FgInput>
                    </div>

                    <div
                      className={
                        model.lmsEnrollmentId ? "mb-3" : "display-none"
                      }
                    >
                      <FgInput
                        id="lmsEnrollmentId"
                        label="LMS Enrollment ID"
                        placeHolder="LMS Enrollment ID"
                        registeredField={registry.lmsEnrollmentId}
                        formState={formState}
                        onChange={(data) => {}}
                        readOnly={true}
                      ></FgInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {!isNew && (
              <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
                <div className="card">
                  <div className="card-body flex flex-col">
                    <strong>2. Course Verification</strong>
                    <div className="pt-4">Under Construction...</div>
                  </div>
                </div>
              </div>
            )} */}
            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  {/* <strong>{isNew ? 2 : 3}. Course Completion</strong> */}
                  <strong>2. Course Curriculum</strong>
                  <div className="pt-4">
                    <FgInput
                      id="courseDescription"
                      label="Course Description"
                      placeHolder="Course Description"
                      registeredField={registry.courseDescription}
                      formState={formState}
                      autoFocus={true}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            courseDescription: data,
                          };
                        });
                      }}
                      maxLength={64}
                      rows={5}
                      disabled={isFromLms()}
                    ></FgInput>
                    <FgInput
                      id="courseObjective"
                      label="Course Objectives"
                      placeHolder="Course Objectives"
                      registeredField={registry.courseObjective}
                      formState={formState}
                      autoFocus={true}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            courseObjective: data,
                          };
                        });
                      }}
                      maxLength={64}
                      rows={5}
                      disabled={isFromLms()}
                    ></FgInput>

                    {showFileUpload() && (
                      <>
                        <div className="mb-3">
                          <label>
                            {isNew
                              ? "Course Media or Attachments"
                              : "Course Media/Attachment or Course Completing Certificate"}
                          </label>

                          {!isFromLms() && (
                            <div>
                              <FileDragNDrop
                                label="Add File"
                                disabled={saving}
                                accepts={{
                                  extensions:
                                    "image/*, video/*, .doc, .docx, .xls, .xlsx, .txt, .rtf, .csv, .pdf",
                                  pattern:
                                    /\.(gif|jpe?g|png|bmp|doc|docx|xls|xlsx|txt|rtf|csv|pdf|mp4|mpeg|mkv|wmv|mov|avi|)$/i,
                                }}
                                dragClickLabel="Drop or upload files here <br/> (Take a photo for mobile)"
                                onChange={(fileList) => {
                                  if (fileList.length > 0) {
                                    const list = [...files];
                                    let cnt = 0;
                                    while (cnt < fileList.length) {
                                      const file = fileList[cnt];
                                      list.push(file);
                                      cnt++;
                                    }
                                    setFiles(list);
                                  }
                                }}
                              />
                            </div>
                          )}
                          <div>
                            {currentData.data?.credFileDtos?.map((cf) => (
                              <span
                                key={cf.id}
                                className="alert alert-secondary m-0 mt-2 flex flex-center"
                              >
                                <i
                                  title="Download File"
                                  className="mr-2 fa fa-download text-primary pointer"
                                  onClick={() => {
                                    if (currentData.data?.id) {
                                      ApsServices.http.credCourseRecord
                                        .downloadTakenCourseCertificate(
                                          currentData.data.id,
                                          cf.id
                                        )
                                        .then((data) => {
                                          const file = commonService.b64toBlob(
                                            data.fileContents,
                                            "text/plain"
                                          );
                                          FileSaver.saveAs(
                                            file,
                                            data.fileDownloadName
                                          );
                                        })
                                        .catch((error) => {
                                          toastStore.showError(
                                            "Failed to Download File",
                                            error
                                          );
                                        });
                                    }
                                  }}
                                ></i>
                                <span className="flex-1 overflow-hidden">
                                  {filesToDelete.includes(cf.id) ? (
                                    <s className="text-danger">
                                      <i>{cf.fileName}</i>
                                    </s>
                                  ) : (
                                    <span>{cf.fileName}</span>
                                  )}
                                </span>
                                {filesToDelete.includes(cf.id) ? (
                                  <i
                                    title="Undo"
                                    className="ml-2 fa fa-undo text-primary pointer"
                                    onClick={() => {
                                      setFilesToDelete(
                                        filesToDelete.filter((f) => f !== cf.id)
                                      );
                                    }}
                                  ></i>
                                ) : isFromLms() ? (
                                  <></>
                                ) : (
                                  <i
                                    title="Delete File"
                                    className="ml-2 fa fa-trash text-danger pointer"
                                    onClick={() => {
                                      setFilesToDelete([
                                        ...filesToDelete,
                                        cf.id,
                                      ]);
                                    }}
                                  ></i>
                                )}
                              </span>
                            ))}
                            {files?.map((f, i) => (
                              <span
                                key={i}
                                className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                              >
                                <span className="flex-1 overflow-hidden">
                                  {f.name}
                                </span>
                                {!saving && (
                                  <i
                                    title="Remove File"
                                    className="mr-1 fa fa-times pointer"
                                    onClick={() => {
                                      const list = [...files];
                                      list.splice(i, 1);
                                      setFiles(list);
                                    }}
                                  ></i>
                                )}
                              </span>
                            ))}

                            {tempFiles.status ===
                              NfirsFetchStatus.InProgress && (
                              <div className="py-2">
                                <CommonSpinner></CommonSpinner>
                              </div>
                            )}
                            {tempFiles.status === NfirsFetchStatus.Complete && (
                              <>
                                {!!tempFiles.data?.length && (
                                  <div className="pt-3">
                                    <label>Select File(s) to Include</label>
                                  </div>
                                )}
                                {tempFiles.data?.map((cf) => (
                                  <span
                                    key={cf.id}
                                    className="alert alert-success m-0 py-2 mt-2 flex flex-center"
                                  >
                                    <i
                                      title="Download File"
                                      className="mr-2 fa fa-download text-primary pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (model.id) {
                                          ApsServices.http.credTemplate
                                            .downloadCourseFile(model.id, cf.id)
                                            .then((data) => {
                                              const file =
                                                commonService.b64toBlob(
                                                  data.fileContents,
                                                  "text/plain"
                                                );
                                              FileSaver.saveAs(
                                                file,
                                                data.fileDownloadName
                                              );
                                            })
                                            .catch((error) => {
                                              toastStore.showError(
                                                "Failed to Download File",
                                                error
                                              );
                                            });
                                        }
                                      }}
                                    ></i>
                                    <span className="flex-1 overflow-hidden">
                                      {filesToInclude.includes(cf.id) ? (
                                        <b>{cf.fileName}</b>
                                      ) : (
                                        <span>{cf.fileName}</span>
                                      )}
                                    </span>
                                    {filesToInclude.includes(cf.id) ? (
                                      <i
                                        title="Exclude File"
                                        className="ml-2 fa fa-check-square text-primary pointer"
                                        onClick={() => {
                                          setFilesToInclude(
                                            filesToInclude.filter(
                                              (f) => f !== cf.id
                                            )
                                          );
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        title="Include File"
                                        className="ml-2 fa fa-square text-secondary pointer"
                                        style={{
                                          fontWeight: "normal",
                                        }}
                                        onClick={() => {
                                          setFilesToInclude([
                                            ...filesToInclude,
                                            cf.id,
                                          ]);
                                        }}
                                      ></i>
                                    )}
                                  </span>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>3. Course Assignment Completion</strong>
                  <div className="pt-4">
                    {!!currentData.data?.employeeName && (
                      <div>
                        <label className="required-label">
                          Search for Participants
                        </label>
                        <div className="alert alert-secondary mb-3 py-2 px-2">
                          {currentData.data.employeeName}
                        </div>
                      </div>
                    )}
                    {isNew && canAddEmployees() && (
                      <>
                        <div>
                          <label className="required-label">
                            Search for Participants
                          </label>{" "}
                        </div>
                        <FgSelect
                          id="_rank"
                          label=""
                          noPlaceholder={true}
                          groups={[
                            { id: 0, label: "By Employee" },
                            { id: -1, label: "By Group" },
                          ]}
                          options={[
                            {
                              label: "Employees",
                              value: 0,
                              groupId: 0,
                            },
                            ...(groups.data || []).map((cat) => {
                              return {
                                label: cat.name,
                                value: cat.groupId,
                                groupId: -1,
                              };
                            }),
                          ]}
                          disabledOptionValues={[-1]}
                          onChange={(data) => {
                            setSelectedGroup(commonService.getNumber(data));
                          }}
                          formState={formState}
                          disabled={!isNew}
                        />

                        <div className="text-primary mt-1 px-1 flex">
                          <div className="flex-1">
                            <span
                              className="pointer"
                              onClick={(e) => {
                                const emps = users
                                  .filter(
                                    (emp) =>
                                      !employees.find((i) => i.id === emp.id)
                                  )
                                  .map((i) => {
                                    return {
                                      id: i.id,
                                      name: i.name,
                                    };
                                  });
                                setEmployees((list) => {
                                  return [...list, ...emps];
                                });
                                if (emps.length > 0) {
                                  toastStore.showToast(
                                    emps.length === 1
                                      ? "1 Employee Added"
                                      : `${emps.length} Employees Added`,
                                    "success"
                                  );
                                } else {
                                  toastStore.showToast(
                                    "No Employee(s) Added",
                                    "warning"
                                  );
                                }
                              }}
                            >
                              {selectedGroup
                                ? `Add All ${
                                    (groups?.data || []).find(
                                      (x) => x.groupId === selectedGroup
                                    )?.name || ""
                                  }`
                                : "Add All Employees"}
                            </span>
                          </div>
                          <div>
                            {employees.length > 0 && (
                              <span
                                className="text-danger pointer"
                                onClick={(e) => {
                                  setDeleteAll(true);
                                }}
                              >
                                Delete All
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <AsyncTypeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onSearch={(e) => {}} //handleSearch
                            open={openEmpDropdown}
                            onFocus={(e) => setOpenEmpDropdown(true)}
                            onBlur={(e) => setOpenEmpDropdown(false)}
                            onChange={(data) => {
                              if (data && data.length > 0) {
                                const prts = [...employees];
                                if (
                                  prts.filter((p) => p.id === data[0].id)
                                    .length === 0
                                ) {
                                  prts.push({
                                    id: data[0].id,
                                    name: data[0].name,
                                  });
                                  setEmployees(prts);
                                } else {
                                  toastStore.showToast(
                                    `${data[0].name} is already in the list.`,
                                    "warning"
                                  );
                                }

                                //setUsers(data);
                                (ref.current as any)?.clear();
                                ref.current?.blur();
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isLoading}
                            options={isLoading ? [] : users}
                            placeholder="Add or Search Employee"
                            promptText={
                              isLoading ? "Loading..." : "No Records Found"
                            }
                            minLength={1}
                            delay={500}
                            useCache={false}
                            ref={ref}
                          />
                        </div>

                        <FormError
                          field={registry.employeeName.name}
                          formState={formState}
                        ></FormError>
                        <div className="mt-3"></div>

                        <div className="currentParticipants mb-3">
                          {employees
                            ?.sort(commonService.sortByStringProperty("name"))
                            ?.map((p, i) => {
                              return (
                                <div key={i}>
                                  <section>{`${p.name}`}</section>
                                  <section
                                    title="Remove"
                                    onClick={() => {
                                      const prts = [...employees];
                                      prts.splice(i, 1);
                                      setEmployees(prts);
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </section>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}

                    {!loading && (
                      <div className="mb-3">
                        <label className="required-label">
                          Date Completed or Scheduled
                        </label>
                        <div className="flex">
                          <DateTimePickerV2
                            data={takenDates?.takenFromDate || null}
                            onChange={(data) => {
                              if (!data) {
                                setTakenDates((prev) => {
                                  return {
                                    ...(prev || ({} as any)),
                                    takenFromDate: null,
                                  };
                                });
                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    takenFromDateString: null,
                                  };
                                });
                                return;
                              }

                              if (model.takenToDateString) {
                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    takenFromDateString:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                  };
                                });
                              } else {
                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    takenFromDateString:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                    takenToDateString:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                  };
                                });
                                setTakenDates((prev) => {
                                  return {
                                    ...(prev || ({} as any)),
                                    takenToDate: data,
                                    takenFromDate: data,
                                  };
                                });
                              }
                            }}
                            dateOnly={true}
                            className="flex-1"
                            disabled={isFromLms()}
                          ></DateTimePickerV2>
                          <div className="flex-0 py-2 px-1">-</div>
                          <DateTimePickerV2
                            key={`${takenDates?.takenToDate || ""}`}
                            data={takenDates?.takenToDate || null}
                            onChange={(data) => {
                              if (!data) {
                                setTakenDates((prev) => {
                                  return {
                                    ...(prev || ({} as any)),
                                    takenToDate: null,
                                  };
                                });
                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    takenToDateString: null,
                                  };
                                });
                                return;
                              }

                              setModel((p: any) => {
                                return {
                                  ...p,
                                  takenToDateString:
                                    commonService.toStartOfDayDateString(data),
                                };
                              });
                            }}
                            dateOnly={true}
                            className="flex-1"
                            disabled={isFromLms()}
                          ></DateTimePickerV2>
                        </div>
                        <FormError
                          formState={formState}
                          field={registry.dateTaken.name}
                        ></FormError>
                      </div>
                    )}

                    <FgInput
                      id="locationTaken"
                      label="Location"
                      placeHolder="Location"
                      registeredField={registry.locationTaken}
                      formState={formState}
                      autoFocus={true}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            locationTaken: data,
                          };
                        });
                      }}
                      maxLength={64}
                      disabled={isFromLms()}
                    ></FgInput>

                    {true && (
                      <>
                        <div>
                          <label>Instructor</label>
                        </div>
                        <div>
                          <AsyncTypeahead
                            id="basic-typeahead-single2"
                            labelKey="name"
                            onSearch={handleSearch2}
                            onChange={(data) => {
                              if (data && data.length > 0) {
                                const prts = [
                                  {
                                    id: data[0].id,
                                    name: data[0].name,
                                  },
                                ];
                                setEmployees2(prts);
                                setUsers2(data);
                                (ref2.current as any)?.clear();
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isLoading2}
                            options={users2}
                            placeholder="Search Instructor"
                            minLength={1}
                            delay={500}
                            useCache={false}
                            ref={ref2}
                            disabled={isFromLms()}
                          />
                        </div>

                        <FormError
                          field={registry.instructor.name}
                          formState={formState}
                        ></FormError>
                        <div className="mt-3"></div>

                        <div className="currentParticipants mb-3">
                          {employees2
                            ?.sort(commonService.sortByStringProperty("name"))
                            ?.map((p, i) => {
                              return (
                                <div key={i}>
                                  <section>{`${p.name}`}</section>
                                  <section
                                    title="Remove"
                                    onClick={() => {
                                      const prts = [...employees2];
                                      prts.splice(i, 1);
                                      setEmployees2(prts);
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </section>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!isNew && (
              <div className="col-12 col-sm-12">
                {/* FOR TESTING ONLY */}
                {/* <label>Notifications (For Testing Only)</label>
                <div
                  className="mb-4"
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "300px",
                    overflow: "auto",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <textarea
                    className="w-100 form-control"
                    style={{
                      backgroundColor: "#eee",
                    }}
                    readOnly={true}
                    rows={10}
                    value={JSON.stringify(
                      currentData.data?.credNotificationTriggerDtos || [],
                      undefined,
                      2
                    )}
                  ></textarea>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default CourseRecord;
