import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Pagination, {
  usePaging,
} from "../../../aps2/components/Common/Pagination";
import ApsModels from "../../../aps2/models";
import {
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import ApsServices from "../../../aps2/services";
import toastStore from "../../../aps2/stores/ToastStore";
import commonService from "../../../aps2/services/CommonService";
import { FilterParameter } from "../../../data/FilterOrderPaginateParameters";
import { AgGridService } from "../../../services/AgGridService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router";
import ConfirmDialog from "../../../aps2/components/Common/ConfirmDialog";
import CommonSpinner from "../../../aps2/components/Common/CommonSpinner";

(window as any).ApsCustomNotificationPaging = {
  // there might be a better solution for this,
  // ag grid server side doesn't seem to see 'paging' variable changes
  // so i have it here instead that can be accessed globally
  page: 1,
  pageSize: 100,
};

let LAST_FILTERS: FilterParameter[] = [];

function CustomNotifications(props: RouteComponentProps<any>) {
  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
    (window as any).ApsCustomNotificationPaging.page = page;
    (window as any).ApsCustomNotificationPaging.pageSize = pageSize;
    gridApi.purgeServerSideCache();
  };

  const [list, setList] =
    useState<ApsModels.ICredTemplateNotificationGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const goToCustomNotif = (
    data: ApsModels.ICredTemplateNotificationGridItemDto
  ) => {
    props.history.push(`/notifications/custom/${data.id}`);
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateNotificationGridItemDto;
  }) => {
    if (rowProps.data?.credNotificationSettingDto?.enableNotifications) {
      return <span className="alert-chip alert alert-success">Enabled</span>;
    }
    return <span className="alert-chip alert alert-danger">Disabled</span>;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateNotificationGridItemDto;
  }) => {
    return (
      <div>
        {canEdit() ? (
          <>
            <button
              type="button"
              className="btn btn-sm btn-outlined"
              title="Edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                goToCustomNotif(rowProps.data);
              }}
            >
              <FontAwesomeIcon className="text-primary" icon={faEdit} />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outlined"
              title="Delete"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setNotifToDel(rowProps.data);
              }}
            >
              <FontAwesomeIcon className="text-danger" icon={faTrash} />
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-sm btn-outlined"
              title="View"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                goToCustomNotif(rowProps.data);
              }}
            >
              <FontAwesomeIcon className="text-primary" icon={faEye} />
            </button>
          </>
        )}
      </div>
    );
  };

  const WhenWhoCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateNotificationGridItemDto;
  }) => {
    return (
      <div className="whenwhocell">
        {rowProps.data?.credNotificationSettingDto?.credNotificationDetails?.map(
          (row) => (
            <div key={row.detailId}>
              -{" "}
              {[
                commonService.isNullOrWhitespace(
                  row.daysBeforeAssignmentDueDate
                )
                  ? ""
                  : `${row.daysBeforeAssignmentDueDate} days before`,
                commonService.isNullOrWhitespace(row.daysAfterAssignmentDueDate)
                  ? ""
                  : `${row.daysAfterAssignmentDueDate} days after`,
                row.notifyOnAssignmentDate ? "On due date" : "",
                row.notifyUponEnrollment ? "Upon assignment" : "",
                row.notifyUponCompletion ? "Upon completion" : "",
                // row.notifyOnExpirationDate
                //   ? "On due date"
                //   : "",
              ]
                .filter((x) => !commonService.isNullOrEmpty(x))
                .join(", ")}
              <span className="mx-1">|</span>
              {[
                row.notifyAccountAdmins ? "Account Admin(s)" : "",
                row.notifyImmediateSupervisors ? "Immediate Supervisor(s)" : "",
                row.notifyParticipants ? "Participants(s)" : "",
                row.notifyOther ? row.notifyOtherEmailAddresses : "",
              ]
                .filter((x) => !commonService.isNullOrEmpty(x))
                .join(", ")}
            </div>
          )
        )}
      </div>
    );
  };

  const colFilterSettings = {
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["reset"],
      filterOptions: ["Contains"],
      maxNumConditions: 1,
      closeOnApply: true,
    },
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        field: "templateName",
        headerName: "Name",
        ...colFilterSettings,
        flex: 1,
      },
      {
        field: "codeNumber",
        headerName: "Code / Number",
        ...colFilterSettings,
      },
      {
        field: "type",
        headerName: "Type",
        valueFormatter: (param: {
          data: ApsModels.ICredTemplateNotificationGridItemDto;
        }) => {
          return param.data?.templateType ===
            ApsModels.CourseOrCredentialEnum.Credential
            ? "Credential"
            : "Course";
        },
        ...colFilterSettings,
      },

      {
        field: "credNotificationSettingDto",
        headerName: "When and Who to Notify",
        cellRenderer: "whenWhoCellComponent",
        menuTabs: [],
        minWidth: 400,
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
        width: 100,
        suppressSizeToFit: true,
        menuTabs: [],
        flex: 0,
      },
      {
        field: "action",
        headerName: "Actions",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        suppressSizeToFit: true,
        menuTabs: [],
        flex: 0,
      },
    ],
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      whenWhoCellComponent: WhenWhoCellComponent,
      statusCellComponent: StatusCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredTemplateNotificationGridItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.credTemplateNotificationGridItemDtos || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const [deleting, setDeleting] = useState(false);
  const [notifToDel, setNotifToDel] =
    useState<ApsModels.ICredTemplateNotificationGridItemDto>();

  const canEdit = () => {
    return commonService.getAccessNotifications() === "All";
  };

  return (
    <div className="flex flex-col h-100 p-4">
      {deleting && (
        <CommonSpinner
          overlay={true}
          message="Deleting Notification..."
        ></CommonSpinner>
      )}
      <ConfirmDialog
        show={!!notifToDel}
        title="Delete Notification"
        message={`Do you really want to delete this notification for '${notifToDel?.templateName}'?`}
        buttons="yesno"
        done={(rtn) => {
          if (notifToDel && rtn === "yes") {
            setDeleting(true);
            ApsServices.http.credTemplate
              .deleteCustomCredentialNotifications(notifToDel.id)
              .then((data) => {
                toastStore.showToast("Notification Deleted", "success");
                setDeleting(false);
                gridApi.purgeServerSideCache();
              })
              .catch((error) => {
                toastStore.showError("Failed Deleting Notification", error);
                setDeleting(false);
              });
          }
          setNotifToDel(undefined);
        }}
      ></ConfirmDialog>

      <div
        className="flex flex-row mb-3 flex-0"
        style={{ flexWrap: "wrap", gap: "10px" }}
      >
        <div
          className="flex-1 flex"
          style={{
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            minWidth: "250px",
          }}
        >
          <div className="mr-4">
            Set up email notifications by clicking "Add Notification", then
            select the credential and desired notifications.
          </div>
        </div>
        <div>
          {commonService.canAccessNotifications() && canEdit() && (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                props.history.push(`/notifications/custom/new`);
              }}
            >
              Add Notification
            </Button>
          )}
        </div>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <div
          id="myGrid"
          style={{
            height: "100%",
            minHeight: "300px",
          }}
          className="ag-theme-alpine flex-1"
        >
          <AgGridReact
            columnDefs={gridState.columnDefs}
            autoGroupColumnDef={gridState.autoGroupColumnDef}
            enableRangeSelection={true}
            animateRows={true}
            onGridReady={onGridReady}
            rowMultiSelectWithClick={false}
            suppressRowDeselection={true}
            suppressRowClickSelection={true}
            frameworkComponents={gridState.frameworkComponents}
            rowModelType="serverSide"
            paginationPageSize={paging.pageSize}
            defaultColDef={gridState.defaultColDef}
            rowSelection="single"
            rowData={gridState.rowData || []}
            onRowDoubleClicked={(event) => {
              goToCustomNotif(event.data);
            }}
            overlayLoadingTemplate={gridState.overlayLoadingTemplate}
            overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
            getRowHeight={(params: {
              data: ApsModels.ICredTemplateNotificationGridItemDto;
            }) => {
              const cnt =
                params.data.credNotificationSettingDto?.credNotificationDetails
                  ?.length || 1;
              if (cnt === 1) {
                return 40;
              }
              return 40 + (cnt - 1) * 24;
            }}
            serverSideDatasource={{
              getRows: async (params: IServerSideGetRowsParams) => {
                const orderParam = AgGridService.convertRequest(params.request)
                  .order[0];

                let filters = AgGridService.convertRequest(
                  params.request
                ).filter;

                const fCnt = filters.length;

                if (fCnt > 1) {
                  filters = filters.filter(
                    (x) => !LAST_FILTERS.find((y) => y.field === x.field)
                  );
                }

                LAST_FILTERS = [...filters];

                if (fCnt > 1) {
                  const cFiltr = params.api.getFilterModel();
                  let mdl = {} as any;
                  mdl[filters[0].field] = cFiltr[filters[0].field];
                  params.api.setFilterModel(mdl); //This will re-trigger getRows
                  return;
                }

                const filterParam = filters.filter(
                  (x) => ["active", "templateType"].indexOf(x.field) === -1
                )[0];

                const activeFilter = filters.filter(
                  (x) => x.field === "active"
                )[0];

                let reqParams: ApsModels.ICredTemplateNotificationsListInputDto =
                  {
                    page: (window as any).ApsCustomNotificationPaging.page,
                    pageSize: (window as any).ApsCustomNotificationPaging
                      .pageSize,
                    orderBy: ApsModels.OrderByEnum.Ascending,
                    credTemplateSort:
                      ApsModels.CredTemplateNotificationSortFilterEnum
                        .TemplateName,
                    credTemplateFilter:
                      ApsModels.CredTemplateNotificationSortFilterEnum
                        .TemplateName,
                    isFilter: false,
                    filter: "",
                    active: true,
                  };

                if (orderParam) {
                  const fieldName = orderParam.field;

                  reqParams.orderBy =
                    orderParam?.direction === "desc"
                      ? ApsModels.OrderByEnum.Descending
                      : ApsModels.OrderByEnum.Ascending;

                  if (fieldName === "templateName") {
                    reqParams.credTemplateSort =
                      ApsModels.CredTemplateNotificationSortFilterEnum.TemplateName;
                  } else if (fieldName === "codeNumber") {
                    reqParams.credTemplateSort =
                      ApsModels.CredTemplateNotificationSortFilterEnum.CodeNumber;
                  } else if (fieldName === "templateType") {
                    reqParams.credTemplateSort =
                      ApsModels.CredTemplateNotificationSortFilterEnum.TemplateType;
                  } else if (fieldName === "active") {
                    reqParams.credTemplateSort =
                      ApsModels.CredTemplateNotificationSortFilterEnum.Active;
                  }
                }

                if (filterParam) {
                  let filterText = "";
                  const fieldName = filterParam.field;

                  if (filterParam && filterParam.value) {
                    filterText = filterParam.value;
                  }

                  if (filterText) {
                    reqParams.isFilter = true;
                    reqParams.filter = filterText;
                  }

                  if (fieldName === "templateName") {
                    reqParams.credTemplateFilter =
                      ApsModels.CredTemplateNotificationSortFilterEnum.TemplateName;
                  } else if (fieldName === "codeNumber") {
                    reqParams.credTemplateFilter =
                      ApsModels.CredTemplateNotificationSortFilterEnum.CodeNumber;
                  } else if (fieldName === "templateType") {
                    reqParams.credTemplateFilter =
                      ApsModels.CredTemplateNotificationSortFilterEnum.TemplateType;
                  } else if (fieldName === "active") {
                    reqParams.credTemplateFilter =
                      ApsModels.CredTemplateNotificationSortFilterEnum.Active;
                  }
                }

                if (activeFilter && activeFilter.values?.length === 1) {
                  reqParams.isFilter = true;
                  reqParams.active = activeFilter.values[0] === "Active";
                  reqParams.credTemplateFilter =
                    ApsModels.CredTemplateNotificationSortFilterEnum.Active;
                } else {
                  reqParams.active = null as any;
                }

                await ApsServices.http.credTemplate
                  .listNotificationSettings(reqParams)
                  .then((data) => {
                    setList(data);
                    if (data.totalRecords === 0) {
                      gridApi && gridApi.showNoRowsOverlay();
                    } else {
                      gridApi && gridApi.hideOverlay();
                    }

                    params.successCallback(
                      data.credTemplateNotificationGridItemDtos,
                      data.credTemplateNotificationGridItemDtos.length
                    );
                  })
                  .catch((err) => {
                    toastStore.showError(
                      "Failed Getting Custom Notifications",
                      err
                    );
                    gridApi && gridApi.hideOverlay();
                  });
              },
            }}
          />
        </div>
      </div>

      <Pagination
        length={list?.totalRecords || 0}
        page={paging.page}
        pageSize={paging.pageSize}
        pageChange={pageChange}
        showingOfWhatLabel="Records"
        sizes={[10, 20, 50, 100]}
      ></Pagination>
    </div>
  );
}

export default CustomNotifications;
